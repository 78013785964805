import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Button } from '../../../../Components/Buttons';
import { LinkStyles, StateStyle } from '../../../../Components/Table/HubSpot/styles';
import { TableHubSpot } from '../../../../Components/Table/HubSpot/tableHubSpot';
import { useGetAccountAppMutation, useGetObjectsMutation, useUpdateObjectsMutation } from '../../../../Store/api/apps';
import { useGetCountTransactionsByClientMutation } from '../../../../Store/api/count';
import { AlertConfirm, AlertConfirmHTML } from '../../../../Utils/Alert';
import { getObjectsQueryUrl } from '../../../../Utils/QueryUrl';
import { formatDate } from '../../../../Utils/times';
import { CheckboxStyles } from './Configure/styles';

export const ObjectView = ({account, generalApp}) => {
  let objQuery = getObjectsQueryUrl(window.location.href);

  let [searchObjectFetch, { isLoading: isLoadingGetObjects, isSuccess: isSuccessObjects, data: responseObjects }] = useGetObjectsMutation();
  let [searchTransactions, { isLoading: isLoadingTransactions, isSuccess: isSuccessTransaction, data: responseTransactions }] = useGetCountTransactionsByClientMutation();
  let [updateObjectFetch, { isLoading: isLoadingUpdObjects, isSuccess: isSuccessUpdObjects }] = useUpdateObjectsMutation();
  const [showFilter, setShowFilter] = useState(false);
  const [dataObject, setDataObject] = useState([]);
  let navigate = useNavigate();
  const columns = useMemo(
    () => [
      {
        id: 'name',
        accessorKey: 'name',
        cell: ({ row, getValue }) => {
          return <>
              <LinkStyles onClick={() => navigate(`/apps/mapping/${row.original.id}${window.location.search}`) }>
                {getValue()}
              </LinkStyles>
              <div>
                <StateStyle className={row.original.active ? "on": "off"}></StateStyle>
                <span>{row.original.active ? "On": "Off"}</span>
              </div>         
            </>
        },
      },
      {
        accessorFn: row => row.inSync,
        id: 'inSync',
        cell: info => info.getValue()?.toLocaleString("en") || "No Info",
        header: () => <span>In Sync</span>,
        meta: {
          filterVariant: 'range',
        },
        size: 135,
      },
      {
        id: 'failing',
        accessorKey: 'failing',
        header: () => 'Failing',
        cell: info => info.getValue()?.toLocaleString("en") || "No Info",
        meta: {
          filterVariant: 'range',
        },
        size: 135,
      },
      {
        id: 'lastEdited',
        accessorFn: row => Date.parse(row.lastEdited.updatedAt),
        header: () => <span>Last Edited</span>,
        cell: ({ row, getValue }) => {
          return <>
              <span> {formatDate(getValue())} </span>
              <div style={{fontSize: "12px"}}>
                {getValue().name} 
              </div>         
            </>
        },
        meta: {
          filterVariant: 'date',
        },
        size: 250,
      },
      {
        id: 'active',
        accessorKey: 'active',
        header: () => <span>Status</span>,
        cell({ row, getValue }) {
            return <CheckboxStyles>
            <label className="switch">
                <input type="checkbox" checked={getValue()} onChange={() => handleSwitchObject(row)}/>
                <span className="slider"></span>
            </label>
          </CheckboxStyles>
          },
        meta: {
            filterVariant: 'hidden',
          },
        size: 160
      },
    ]
  )

  useEffect(() => {
    if(!generalApp || !account) return;
   
    searchObjectFetch({
      accountId: objQuery.accountId,
      appId: objQuery.appId,
      portalId: objQuery.portalId
    });
    let categoriesApp = generalApp.config.categoryObject;
    transactionRequest(categoriesApp);
  }, [generalApp, account])
  
  useEffect(() => {
    if(isSuccessObjects && isSuccessTransaction) {
      let categoriesApp = generalApp.config.categoryObject;

      let includesDataSync = []
      for (const item of responseObjects.data.objects) {
        let findConfigObject = categoriesApp[item.id];
        let findData = responseTransactions.data.filter(el => findConfigObject.includes(el.category));
        includesDataSync.push({
          ...item,
          inSync: findData.reduce((a, b) => a + Number(b.sync_count), 0),
          failing: findData.reduce((a, b) => a + Number(b.fail_count), 0),
        })
      }
      setDataObject(includesDataSync);
    }
  }, [isSuccessObjects, isSuccessTransaction])
   
  const transactionRequest = async (categoriesApp) => {
    let categoriesList = [];

    for (const key in categoriesApp) {
      if (Object.hasOwnProperty.call(categoriesApp, key)) {
        const element = categoriesApp[key];
        categoriesList.push(...element)
      }
    }

    let idClientIntegrator = account.data.AppsSetting?.id_client_integrador || account.data.VtexSetting?.id_client_integrador || account.data.ShopifySetting?.id_client_integrador
    searchTransactions(
      {idClient: idClientIntegrator, dateStart: "2023-01-01", dateEnd: new Date().toJSON(),  categories: categoriesList},
    )
  }

  const handleSwitchObject = async (row ) => {
    let listValidation = [];

    let findValidation =  generalApp.dependencies.validationObject.find(validation => row.original.id === validation.object && !row.original.active === validation.active);
    if(findValidation) {
        for (const item of findValidation.required) {
          listValidation.push(`The ${item.object} object will be ${item.active ? "activate": "deactivate"}`)
        }
    }
    
    let dataHtml = listValidation.map(el => {
      return `<li>${el}</li>`;
    })

    let result = await AlertConfirmHTML({title: `You want to ${row.original.active ? "deactivate": "activate"} the synchronization of the ${row.original.name} object`, html: `<ul style="text-align: left;">${dataHtml.join("")}</ul>`})
   
    if(result.isConfirmed) {
      /* debugger */
      let now = new Date();
      now = now.toJSON();
      
      let updateData = JSON.parse(JSON.stringify(dataObject));
      let findObject = updateData.find(el => el.id === row.original.id);
      
      findObject.active = !row.original.active;
      findObject.lastEdited.name = objQuery.userEmail;
      findObject.lastEdited.updatedAt = now;

      if(findValidation) {
        for (const item of findValidation.required) {
          let findObject = updateData.find(el => el.id === item.object);
          findObject.active = item.active;
        }
      }
      

      setDataObject(updateData);
      updateObjectFetch({
        accountId: objQuery.accountId,
        appId: objQuery.appId,
        portalId: objQuery.portalId,
        objects: updateData
      })
    }
  }
  const handleSyncNow = () => {
    searchObjectFetch({
      accountId: objQuery.accountId,
      appId: objQuery.appId,
      portalId: objQuery.portalId
    });
    let categoriesApp = generalApp.config.categoryObject;
    transactionRequest(categoriesApp)
  }

  return (
   <>
    <h2 className='h2'>Object View</h2>
    <section>
      <div className='section-btn'>
        <Button className={"hubspot button--sm"} onPress={() => setShowFilter(!showFilter)}>{showFilter ? "Hidden" : "Show"} filters</Button>
        <Button className={"hubspot button--sm"} onPress={handleSyncNow}>Refresh</Button>
      </div>
      <div>
        <TableHubSpot dataTable={dataObject || []} showFilter={showFilter} columns={columns} rowExpand={false} />
      </div>
    </section>
   </>
  );
};
